import React from 'react';
import { useTranslation } from 'next-i18next';
import {
  CategoryItems,
  FirstLevelCategory,
  FirstLevelTitleAndDropdown,
  FirstLevelTitleWrapper,
  SecondAndThirdLevelPadding,
  SecondAndThirdLevelWrapper,
  SecondLevelCategoryLink,
  SecondLevelCategoryLinkText,
  ThirdLevelCategories,
  ThirdLevelCategoryLink,
  ThirdLevelCategoryText,
} from '@components/_new_components/Common/layout/Category/Category.styles';
import { CustomText } from '@components/ui/Text';
import { buttonClickLog } from '@lib/mixpanel/events';
import CategoryIcon from '@components/_new_components/Common/layout/Category/CategoryIcon';
import CategoryChevronIcon from '../../../../../src/assets/icons/category/CategoryChevronIcon';

interface Props {
  isActiveCategoryCode: string | null;
  firstLevelCategory: any;
  expandedCategoryCode: string;
  handleCategoryGroupClick: (categoryId: string) => void;
  isSideCategory?: boolean;
}

export default function CategoryItem({ isActiveCategoryCode, firstLevelCategory, expandedCategoryCode, handleCategoryGroupClick }: Props) {
  const { t } = useTranslation();
  const iconRef = React.useRef<HTMLDivElement>(null);
  const secondLevelCategories = firstLevelCategory.children;
  const isExpanded = expandedCategoryCode === firstLevelCategory.code;

  const [isHover, setIsHover] = React.useState(false);

  // 3뎁스가 없는 카테고리가 존재하고 이러한 경우 해당 2뎁스 카테고리 모두 3뎁스는 존재하지 않음.
  // 그러므로 첫 번째 항목에 3뎁스가 없을 경우 모두 없을 것으로 가정함.
  const isOnlySecondLevel = secondLevelCategories[0].children.length === 0;

  const categoryHeight = React.useMemo(() => {
    // 카테고리 높이 계산
    const firstLevelCategoryHeight = 73;
    const secondLevelCategoryTextHeight = 37;
    const thirdLevelCategoryTextHeight = 35;
    const secondLevelAndThirdLevelCategoriesHeight = secondLevelCategories
      .map((secondLevelCategory, idx) => {
        if (isOnlySecondLevel) return thirdLevelCategoryTextHeight;
        const gap = idx === 0 ? 0 : 8;
        const children = secondLevelCategory.children;

        return secondLevelCategoryTextHeight + children.length * thirdLevelCategoryTextHeight + gap;
      })
      .reduce((a, b) => a + b, 0);
    return secondLevelAndThirdLevelCategoriesHeight + firstLevelCategoryHeight + 22;
  }, [firstLevelCategory.id]);

  const handleCategoryClick = (categoriesName: string[], categoryCode: string) => {
    return () => {
      buttonClickLog({
        actionName: 'category click',
        categoryName: categoriesName.join('_'),
        categoryCode: categoryCode,
      });
    };
  };

  const handleCategoryMouseOver = () => {
    setIsHover(true);
    if (!iconRef?.current || isHover || isExpanded) return;
    const svgElement = iconRef.current.getElementsByTagName('svg') as any;
    svgElement[1].setCurrentTime(0);
  };

  const handleCategoryMouseOut = () => {
    if (isExpanded) return;
    setIsHover(null);
  };

  React.useEffect(() => {
    if (!isExpanded) setIsHover(null);
  }, [isExpanded]);

  return (
    <FirstLevelCategory
      onClick={() => {
        handleCategoryGroupClick(firstLevelCategory.code);
      }}
      onMouseOver={handleCategoryMouseOver}
      onMouseOut={handleCategoryMouseOut}
    >
      <FirstLevelTitleAndDropdown isExpanded={isExpanded}>
        <FirstLevelTitleWrapper>
          <CategoryIcon firstLevelCategoryCode={firstLevelCategory.code} ref={iconRef} />

          <CustomText color={'#18181b'} size={16} weight={600}>
            {firstLevelCategory.name}
          </CustomText>
        </FirstLevelTitleWrapper>

        <CategoryChevronIcon isExpanded={isExpanded} />
      </FirstLevelTitleAndDropdown>

      <SecondAndThirdLevelWrapper isExpanded={isExpanded} height={categoryHeight} onClick={(e) => e.stopPropagation()}>
        <CategoryItems>
          <SecondLevelCategoryLink
            href={`/category/${firstLevelCategory.code}`}
            isActive={firstLevelCategory.code === isActiveCategoryCode}
            onClick={handleCategoryClick([firstLevelCategory.name], firstLevelCategory.code)}
          >
            <SecondLevelCategoryLinkText>{t('category:all')}</SecondLevelCategoryLinkText>
          </SecondLevelCategoryLink>
        </CategoryItems>
        {/** 3뎁스 카테고리까지 있는 경우 */}
        {!isOnlySecondLevel &&
          secondLevelCategories.map((secondLevelCategory) => (
            <CategoryItems key={secondLevelCategory.id}>
              <SecondLevelCategoryLink
                href={`/category/${secondLevelCategory.code}`}
                isActive={secondLevelCategory.code === isActiveCategoryCode}
                onClick={handleCategoryClick([firstLevelCategory.name, secondLevelCategory.name], secondLevelCategory.code)}
              >
                {secondLevelCategory.name}
              </SecondLevelCategoryLink>

              <ThirdLevelCategories>
                {secondLevelCategory.children.map((thirdCategory) => (
                  <ThirdLevelCategoryLink
                    href={`/category/${thirdCategory.code}`}
                    key={thirdCategory.id}
                    onClick={handleCategoryClick([firstLevelCategory.name, secondLevelCategory.name, thirdCategory.name], thirdCategory.code)}
                  >
                    <ThirdLevelCategoryText isActive={thirdCategory.code === isActiveCategoryCode}>{`-  ${thirdCategory.name}`}</ThirdLevelCategoryText>
                  </ThirdLevelCategoryLink>
                ))}
              </ThirdLevelCategories>
            </CategoryItems>
          ))}

        {/** 2뎁스 카테고리까지 있는 경우 */}
        {isOnlySecondLevel && (
          <CategoryItems>
            <ThirdLevelCategories>
              {secondLevelCategories.map((secondLevelCategory) => (
                <ThirdLevelCategoryLink
                  href={`/category/${secondLevelCategory.code}`}
                  key={secondLevelCategory.id}
                  onClick={handleCategoryClick([firstLevelCategory.name, secondLevelCategory.name], secondLevelCategory.code)}
                >
                  <ThirdLevelCategoryText isActive={secondLevelCategory.code === isActiveCategoryCode}>{`- ${secondLevelCategory.name}`}</ThirdLevelCategoryText>
                </ThirdLevelCategoryLink>
              ))}
            </ThirdLevelCategories>
          </CategoryItems>
        )}

        <SecondAndThirdLevelPadding />
      </SecondAndThirdLevelWrapper>
    </FirstLevelCategory>
  );
}
