import styled from '@emotion/styled';
import { ScrollTopContainer } from '@components/ScrollTop/ScrollTop.styles';
import { SnackbarContainer } from '@components/_new_components/Common/layout/BottomController/Snackbar/Snackbar.styles';
import { BandBannerContainer } from '@components/_new_components/Common/layout/BottomController/BandBanner/BandBanner.styles';
import { Wrapper as RecommendLanguageWrapper } from '@components/_new_components/Common/layout/Footer/RecommendLanguage/RecommendLanguage.styles';

export const BottomControllerWhiteSpace = styled.div`
  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    padding-bottom: 56px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    padding-bottom: 48px;
  }
`;

export const BottomControllerContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 200;
`;

export const AbsoluteContents = styled.div<{ bottom: number; hideMobileBottomTab: boolean }>`
  position: absolute;
  bottom: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: none;

  a,
  button,
  ${ScrollTopContainer}, ${SnackbarContainer}, ${RecommendLanguageWrapper}, ${BandBannerContainer} {
    pointer-events: auto;
  }

  ${(props) =>
    props.hideMobileBottomTab &&
    `
      @supports (content: env(safe-area-inset-bottom)) {
        min-height: env(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
      }
    
      @supports (content: constant(safe-area-inset-bottom)) {
        min-height: constant(safe-area-inset-bottom);
        padding-bottom: constant(safe-area-inset-bottom);
      }
  `}

  ${(props) =>
    props.bottom &&
    `
    bottom: ${props.bottom}px;
  `}
`;

export const SidePadding = styled.div`
  padding: 0 24px 24px 24px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    padding: 0 16px 16px 16px;
  }
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BottomPortalContents = styled.div``;

export const KeyboardSpacer = styled.div<{ bottom: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${(props) =>
    props?.bottom &&
    `
    width: calc(100% - 32px);
    position: fixed;
    bottom: ${props.bottom}px;
  `}
`;
